<template>
  <div class="ButtonDecoration">
    <component
      :is="tag"
      class="ButtonDecoration__Button"
      :class="{'-gold': buttonColor === 'gold'}"
      :disabled="disabled"
      @click="$emit('click')"
    >
      <slot />
    </component>
    <div class="ButtonDecoration__Ornaments">
      <div v-for="item in ornament" :key="item" class="ButtonDecoration__Ornament" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    buttonColor: {
      type: String,
      default: 'blue'
    },
    tag: {
      type: String,
      default: 'button'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ornament () {
      const ornaments = new Array(10)
      return ornaments
    }
  }
}
</script>
<style lang="scss" scoped>
.ButtonDecoration {
  @at-root {
    & {
      height: 210px;
      background: url(#{$img-path}button-decoration/bg.png) no-repeat center 0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }
    .ButtonDecoration__Button {
      @include tap-event();
      width: 584px;
      height: 198px;
      background: url(#{$img-path}button-decoration/btn_blue.png) no-repeat center 0;
      &.-gold {
        background: url(#{$img-path}button-decoration/btn_gold.png) no-repeat center 0;
      }
      // FIXME -disabledクラスが使われていない
      &:disabled,
      &.-disabled {
        opacity: 1;
        pointer-events: none;
        cursor: default;
        img,
        span {
          opacity: .3;
        }
      }
    }
    .ButtonDecoration__Ornaments {
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
    }
    .ButtonDecoration__Ornament {
      width: 40px;
      height: 40px;
      background: url(#{$img-path}common/glitter.png) no-repeat;
      position: absolute;
      opacity: 0;
      z-index: 100;
      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          top: random(160) + px;
          left: random(580) + px;
          animation: TopEffect .6s random(20) * 0.1 + s ease-out both infinite alternate;
        }
      }
    }
  }
}

@keyframes TopEffect {
  0% {
    opacity: 0;
    transform: scale(.1);
  }
  100% {
      opacity: 1;
      transform: scale(1.2);
  }
}
</style>
