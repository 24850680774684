<template>
  <div class="Top">

    <div class="Top__Visual Visual">
      <ul class="Visual__List">
        <li
          v-for="(item, i) in cardList"
          :key="i"
          class="Visual__ListItem"
        >
          <img :src="require(`@images/characters/card/${item}.png`)" alt="">
        </li>
      </ul>
    </div>

    <gold-box class="Top__Body Body">
      <h1 class="Top__Title Title">
        <img
          :src="require(`@images/top/${locale}/logo.png`)"
          :alt="$t('top.001')+$t('common.title')"
        >
      </h1>
      <div class="Top__Outline Outline">
        <div class="GoldBox__Block">
          <h2 class="Outline__Title">
            <img
              :src="require(`@images/top/${locale}/text_present.png`)"
              :alt="$t('top.004')"
            >
          </h2>
          <p class="Outline__Text" v-html="$t('top.005')" />
          <dl class="Outline__Period OutlinePeriod">
            <dt class="OutlinePeriod__Title">
              <img
                :src="require(`@images/top/${locale}/text_period.png`)"
                :alt="$t('top.007')"
              >
            </dt>
            <dd class="OutlinePeriod__Text" v-html="$t('top.008')" />
          </dl>
          <div class="Outline__Links">
            <a
              href="https://support.fire-emblem-heroes.com/voting_gauntlet/howtoplay"
              target="_blank"
              class="Outline__Link"
              v-html="$t('top.006')"
            />
          </div>
        </div>
      </div>
      <btn-decoration
        v-if="resultOpen"
        class="Top__EnterButton"
        button-color="gold"
        @click="$router.push({name: 'Result', params: {lang: locale}})"
      >
        <img
          :src="require(`@images/top/${locale}/btn_text_result.png`)"
          :alt="$t('top.011')"
        >
      </btn-decoration>
      <btn-decoration
        v-else
        class="Top__EnterButton"
        :disabled="!voteOpen"
        @click="clickHeroSelect"
      >
        <img
          :src="require(`@images/top/${locale}/btn_text_select.png`)"
          :alt="$t('top.010')"
        >
      </btn-decoration>
      <div v-if="showMainSns" class="Top__Sns Sns">
        <h2 class="Sns__Title" v-html="$t('top.009')" />
        <ul class="Sns__Items">
          <li v-if="locale !== 'ja-jp'" class="Sns__Item">
            <a :href="$t('vars.facebook')" target="_blank" rel="noopener noreferrer">
              <img src="@images/top/btn_facebook.png" alt="facebook">
            </a>
          </li>
          <li class="Sns__Item">
            <a :href="$t('vars.twitter')" target="_blank" rel="noopener noreferrer">
              <img src="@images/top/btn_twitter.png" alt="twitter">
            </a>
          </li>
        </ul>
      </div>
    </gold-box>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LocaleMixin from '@/mixins/locale'
import LoaderMixin from '@/mixins/loader'
import ModelModal from '@/models/modal'
import NaLogin from '@/components/modals/na-login.vue'
import GoldBox from '@/components/global/gold-box.vue'
import BtnDecoration from '@/components/global/button-decoration.vue'

export default {
  name: 'Top',
  mixins: [LocaleMixin, LoaderMixin],
  components: {
    GoldBox,
    BtnDecoration
  },
  computed: {
    ...mapGetters('term', ['voteOpen', 'resultOpen']),
    ...mapGetters('login', ['isLogin']),
    cardList () {
      let list = [465, 483, 488, 489, 500, 503, 504, 511, 515, 518, 521, 522, 527, 529, 532, 534, 537, 538, 543, 545, 549, 551, 554, 555, 556, 562, 566, 567, 570, 571, 574, 584, 585, 587, 595, 600, 608, 611, 616, 619]
      const shuffle = ([...array]) => {
        for (let i = array.length - 1; i >= 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]]
        }
        return array
      }
      list = shuffle(list)
      return list.concat(list.slice(0, 2))
    },
    showMainSns () {
      const whitelist = ['ja-jp', 'en-us', 'en-gb']
      return whitelist.some(w => w === this.locale)
    },
    isJaJp () {
      return this.locale === 'ja-jp'
    }
  },
  methods: {
    ...mapActions('modal', { showModal: 'show' }),
    clickHeroSelect () {
      if (this.isLogin) {
        this.$router.push({ name: 'Vote', params: { lang: this.locale } })
      } else {
        this.showModal(new ModelModal({
          component: NaLogin,
          props: {
            hideCloseBtn: false,
            toPath: 'vote'
          }
        }))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.Top {
  @at-root {
    & {
      margin-bottom: 64px;
    }
    .Top__Visual {
      margin-top: 40px;
    }
    .Top__Body {
      margin: 268px auto 162px;
    }
    .Top__Title {
      position: absolute;
      top: -271px;
      left: 0;
      right: 0;
    }
    .Top__Outline {
      margin-top: 153px;
      margin-bottom: 24px;
    }
    /*
      How to override the CSS of a generic component
      https://github.dena.jp/GamePromo-Ext/Brave-Vote5/issues/379#issuecomment-1823841
      & .Top__EnterButton {}
      -> .Top .Top__EnterButton {}
    */
    .Top__EnterButton {
      margin-top: 5px;
    }
    .Top__Sns {
      margin-top: 13px;
    }
  }
}

.Visual {
  @at-root {
    & {
      position: relative;
      width: 100%;
      height: 286px;
      overflow: hidden;
      z-index: 20;
      backface-visibility: hidden;
    }
    .Visual__List {
      position: absolute;
      top: 0;
      left: 0;
      width: 18400px;
      height: inherit;
      display: flex;
      animation: Loop 200s linear infinite;
    }
    .Visual__ListItem {
      margin: 0 6px;
      flex: 0 0 448px;
      width: 448px;
      height: inherit;
      backface-visibility: hidden;// これがないとlayer-borderの関係で画像が切れることがある
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

.Title {
  @at-root {
    & {
      display: flex;
      justify-content: center;
      height: 443px;
    }
  }
}

.Outline {
  @at-root {
    & {
      display: flex;
      justify-content: center;
    }
    .Outline__Title {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 86px;
    }
    .Outline__Text {
      width: 495px;
      margin: 25px auto 0;
    }
    .Outline__Period {
      margin-top: 20px;
    }
    .Outline__Links {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
    }
    .Outline__Link {
      display: flex;
      align-items: center;
      &::before {
        content: '';
        display: block;
        width: 40px;
        height: 28px;
        margin-top: 4px;
        margin-right: 5px;
        background: url(#{$img-path}common/icon_arrow.png) no-repeat;
      }
    }
  }
}

.OutlinePeriod {
  @at-root {
    & {
    }
    .OutlinePeriod__Title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
    }
    .OutlinePeriod__Text {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 491px;
      min-height: 44px;
      margin: 12px auto 0;
      padding: 4px;
      background-color: rgba(38, 34, 19, .6);
      text-align: center;
      font-size: $fs-large;
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        display: block;
        width: 15px;
        background: url(#{$img-path}top/bg_period.png) no-repeat;
        background-size: 100% 100%;
      }
      &::before {
        left: -15px;
      }
      &::after {
        right: -15px;
        transform: scaleX(-1);
      }
    }
  }
}

.Sns {
  @at-root {
    & {
    }
    .Sns__Title {
      width: 576px;
      margin: 0 auto;
      padding: 0 10px;
      font-size: $fs-3large;
      font-weight: normal;
      color: $color-sub;
      text-align: center;
    }
    .Sns__Items {
      display: flex;
      justify-content: center;
      margin-top: 9px;
    }
    .Sns__Item {
      display: block;
      width: 260px;
      height: 88px;
      margin: 0 5px;
      @include tap-event();
    }
  }
}

@keyframes Loop {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-18400px);
  }
}
</style>
